/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-16 15:35:04
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-23 09:59:51
 */
import { createNamespacedHelpers } from 'vuex'
import IconTitle from '../common/iconTitle'

const { mapGetters } = createNamespacedHelpers('App/Overview')

export default {
  name: 'TodayData',
  components: {
    IconTitle
  },
  data () {
    return {}
  },
  computed: {
    ...mapGetters([
      'todayData'
    ])
  }
}
